import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"
import { CTABlock } from "../../components/CTABlock"
import helpers from "../../styles/helpers.module.css"
import styles from "./blog.module.css"

export default function Template({ data }) {
  const domain = data.site.siteMetadata.domain
  const { markdownRemark } = data
  const { frontmatter, html, fields } = markdownRemark
  const {
    date,
    title,
    path,
    author,
    category,
    excerpt,
    authorImage,
  } = frontmatter

  const { readingTime } = fields

  const isSSR = typeof window === "undefined"

  if (!isSSR) {
    const fbShare = () => {
      return ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        const js = d.createElement(s)
        js.id = id
        js.src =
          "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
    }

    const twitterShare = () => {
      window.twttr = (function(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        const t = window.twttr || {}
        if (d.getElementById(id)) return t
        const js = d.createElement(s)
        js.id = id
        js.src = "https://platform.twitter.com/widgets.js"
        fjs.parentNode.insertBefore(js, fjs)

        t._e = []
        t.ready = function(f) {
          t._e.push(f)
        }

        return t
      })(document, "script", "twitter-wjs")
    }

    twitterShare()
    fbShare()
  }

  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <div className="bg-gray-100">
        <div className="container px-6 py-32 mx-auto sm:px-8 md:px-12 md:py-40 md:px-60 lg:px-16 lg:py-48 xl:py-56">
          <div className="flex flex-col">
            <div className="flex">
              <p className={`${helpers.chipDark} mb-4`}>{category}</p>
            </div>
            <h1 className="mt-2 mb-4 tracking-wide sm:text2xl">{title}</h1>
            <p className="mb-8 text-xl">{excerpt}</p>
            <p className="font-semibold text-gray-900">{readingTime.text}</p>
          </div>
        </div>
      </div>
      <div className="container px-6 mx-auto sm:px-8 md:px-12 lg:px-16">
        <div className="grid grid-cols-12 gap-8 py-8 md:py-16">
          <div className="col-span-12 lg:col-span-2">
            <div className="flex items-center">
              <div className="w-12 h-12 mr-2 overflow-hidden rounded-full">
                <img className="w-full h-full" src={authorImage} alt={author} />
              </div>
              <div>
                <p className="font-semibold text-gray-900">{author}</p>
                <p className={`${helpers.caption}`}>{date}</p>
              </div>
            </div>
          </div>
          <div
            className={`${styles.blogContent} col-span-12 lg:col-span-8`}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="lg:col-span-2">
            <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              className="twitter-share-button"
              data-show-count="false"
            >
              Tweet
            </a>
            <div
              className="fb-share-button"
              data-href={`${domain}${path}`}
              data-layout="button"
              data-show-count="false"
            >
              Share to Facebook
            </div>
          </div>
        </div>
      </div>
      <CTABlock />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        id
        date(formatString: "MMMM DD, YYYY")
        title
        path
        author
        category
        excerpt
        authorImage
      }
      fields {
        readingTime {
          text
        }
      }
    }
    site {
      siteMetadata {
        domain
      }
    }
  }
`
