import React from "react"
import { Link } from "gatsby"
import buttons from "../../styles/buttons.module.css"

export const CTABlock = () => {
  return (
    <div className="bg-gray-900">
      <div className="container px-6 py-24 mx-auto sm:px-8 md:px-12 lg:px-6 xl:px-16">
        <div>
          <div className="grid grid-cols-12 gap-6 lg:items-center">
            <div className="flex flex-col col-span-12 sm:col-span-10 sm:col-start-2 lg:col-span-7">
              <h2 className="mb-8 text-5xl tracking-wide text-white lg:text-5xl lg:text-left">
                Book your appointment today.
              </h2>
              <p className="mx-auto mb-8 ml-0 text-xl tracking-wide text-white lg:mb-0 lg:text-left">
                Ready to book your appointment with our team? A bespoke
                consultation, without obligation, is provided before each
                treatment.
              </p>
            </div>
            <div className="col-span-12 sm:col-span-10 md:col-start-3 md:col-span-8 lg:col-start-9 lg:col-span-2">
              <Link
                className={`${buttons.btnSecondary} block lg:mt-0 md:col-start-10 md:col-span-2`}
                to="/contact"
              >
                Contact
              </Link>
            </div>
            <div className="col-span-12 sm:col-span-10 md:col-start-3 md:col-span-8 lg:col-span-2">
              <Link
                className={`${buttons.btnSecondaryOutlineReverse} block md:col-start-10 md:col-span-2 lg:mt-0`}
                to="/blog"
              >
                Read blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
